<template>
    <div class="md-layout text-center">
        <div class="ti md-layout md-layout-item md-size-70 md-xsmall-size-100">
            <div class="md-layout-item md-size-100">
                <p class="md-display-1">
                    You've been invited to join
                    <template v-if="contactName"> the {{ contactName }}'s </template>
                    <template v-else> an </template>
                    organization
                </p>
                <p class="md-subheading">Please create an account to accept the invitation</p>
            </div>

            <div class="md-layout-item md-size-100">
                <div class="social-line text-center">
                    <md-button
                        class="custom-button md-just-icon md-round md-google"
                        @click="handleSocialButtonClick('google')"
                    >
                        <i class="ti__social-button fab fa-google-plus-g"></i>
                    </md-button>
                    <md-button
                        class="custom-button md-just-icon md-round md-facebook"
                        @click="handleSocialButtonClick('facebook')"
                    >
                        <i class="ti__social-button fab fa-facebook-f"></i>
                    </md-button>
                </div>
            </div>

            <div class="ti__signup-form md-layout-item md-size-100">
                <md-field class="md-form-group" v-for="item in inputs" :key="item.name">
                    <md-icon>{{ item.icon }}</md-icon>
                    <label>{{ item.name }}</label>
                    <md-input
                        v-model="item.value"
                        :type="item.type"
                        required
                        @keyup.enter="register"
                        :disabled="item.type === 'email'"
                    ></md-input>
                </md-field>
                <md-checkbox v-model="termsAndConditions" :class="{ 'ti__checkbox--error': showCheckboxError }"
                    >I agree to the <a>terms and conditions</a>.</md-checkbox
                >
                <p class="md-caption text-danger" v-if="errorMessage">
                    {{ errorMessage }}
                </p>
            </div>

            <div class="md-layout-item md-size-100">
                <md-button :disabled="!isFormValid" v-if="!loading" class="md-primary md-block" @click="register">
                    <span class="md-body-2">Sign Up</span>
                </md-button>
                <md-progress-spinner
                    v-else
                    class="md-primary"
                    :md-diameter="20"
                    :md-stroke="3"
                    md-mode="indeterminate"
                ></md-progress-spinner>
            </div>
        </div>
    </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { mapActions } from 'vuex';
import userService from '@/services/account/userService';

export default {
    name: 'TheInvite',
    data() {
        return {
            // Query params.
            account: null,
            user: null,
            contactName: null,
            userEmail: null,

            termsAndConditions: false,
            showCheckboxError: false,
            errorMessage: null,
            loading: false,
            inputs: [
                {
                    icon: 'email',
                    name: 'Email...',
                    nameAttr: 'email',
                    type: 'email',
                    value: null,
                    formatValue: (value) => value.replace(/\s/g, '+'),
                },

                {
                    icon: 'lock_outline',
                    name: 'Password..',
                    nameAttr: 'password',
                    type: 'password',
                    value: null,
                },
            ],
        };
    },
    computed: {
        isFormValid() {
            const allInputsFilled = this.inputs.every((input) => input.value);
            const isCheckboxChecked = this.termsAndConditions;
            return allInputsFilled && isCheckboxChecked;
        },
    },
    created() {
        this.fetchQueryParams();
    },
    methods: {
        ...mapActions('user', [
            'actionGetUser',
            'actionSignup',
            'actionCreateAccount',
            'refreshToken',
            'actionGetAccount',
            'actionSignUpWithPopup',
            'actionSignUpWithFacebook',
        ]),
        async register() {
            const [rawEmail, password] = this.inputs.map((input) => input.value);
            const email = this.inputs.find((input) => input.type === 'email').formatValue(rawEmail);
            const myUuid = uuidv4();
            this.errorMessage = '';
            this.loading = true;

            // Validation
            if (!this.validateInputs(email, password)) {
                this.loading = false;
                return;
            }

            try {
                const userData = await this.actionSignup({ email, password });

                if (!userData) {
                    throw new Error('Authentication failed');
                }

                await userService.acceptInvitation(this.user, userData.localId);
                await this.refreshToken();
                await this.actionGetUser();
                const response = await this.actionGetAccount();

                const successfulResponse = [200, 201].includes(response.status);
                if (successfulResponse) {
                    this.$router.push({ name: 'Dashboard' });
                    this.showSuccessToast('Sign up complete!');
                }
            } catch (err) {
                const errorMessage = 'An error occurred.';
                this.errorMessage = errorMessage;
                console.error('Error:', errorMessage);
                this.logout();
            } finally {
                this.loading = false;
            }
        },

        async handleSocialButtonClick(platform) {
            if (!this.termsAndConditions) {
                this.showCheckboxError = true;
                setTimeout(() => (this.showCheckboxError = false), 1000); // Reset after animation
                return;
            }
            await this.registerWithSocialMedia(platform);
        },

        async registerWithSocialMedia(platform) {
            const myUuid = uuidv4();
            this.errorMessage = '';
            this.loading = true;

            try {
                // Determine the appropriate action based on the platform
                let signupResponse;
                if (platform === 'google') {
                    signupResponse = await this.actionSignUpWithPopup();
                } else if (platform === 'facebook') {
                    signupResponse = await this.actionSignUpWithFacebook();
                } else {
                    throw new Error('Unsupported platform');
                }

                if (!signupResponse) {
                    this.errorMessage = 'Authentication failed';
                    return;
                }

                await userService.acceptInvitation(this.user, signupResponse._tokenResponse.localId);
                await this.refreshToken();
                await this.actionGetUser();
                const response = await this.actionGetAccount();
                if (response.status === 200 || response.status === 201) {
                    this.$router.push({ name: 'Dashboard' });
                    this.showSuccessToast('Sign up complete!');
                }
            } catch (err) {
                const errorMessage = 'An error occurred.';
                this.errorMessage = errorMessage;
                console.error('Error:', errorMessage, err);
                this.logout();
            } finally {
                this.loading = false;
            }
        },

        fetchQueryParams() {
            this.account = this.$route.query.account;
            this.user = this.$route.query.user;
            this.contactName = this.$route.query.contactName;

            const emailInputIndex = this.inputs.findIndex((input) => input.type === 'email');
            const rawEmail = this.$route.query.userEmail;
            this.inputs[emailInputIndex].value = rawEmail ? this.inputs[emailInputIndex].formatValue(rawEmail) : null;
            this.userEmail = this.inputs[emailInputIndex].value;
        },

        validateInputs(email, password) {
            // Format the email before validation
            email = this.inputs.find((input) => input.type === 'email').formatValue(email);

            if (!email || !password) {
                this.errorMessage = 'All fields are required';
                return false;
            }

            if (!this.isValidEmail(email)) {
                this.errorMessage = 'Invalid email format';
                return false;
            }

            if (!this.isValidPassword(password)) {
                this.errorMessage = 'Password must be at least 6 characters and include alphanumeric characters';
                return false;
            }

            return true;
        },

        showSuccessToast(msg) {
            this.$toasted.success(msg, {
                position: 'bottom-center',
                icon: 'check_circle',
                duration: 3000,
            });
        },

        isValidEmail(email) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return emailRegex.test(email);
        },

        isValidPassword(password) {
            const passwordRegex = /^(?=.*[0-9a-zA-Z]).{6,}$/;
            return passwordRegex.test(password);
        },
    },
};
</script>

<style scoped lang="scss">
.ti {
    padding-left: 0;
    padding-right: 0;
}

.ti__signup-form {
    margin-top: 15px;
    margin-bottom: 30px;
}

.ti__social-button {
    color: #fff !important;
}

.ti__checkbox--error {
    animation: shake 0.5s;
    color: red !important;
}

@keyframes shake {
    0%,
    100% {
        transform: translateX(0);
    }
    25%,
    75% {
        transform: translateX(-10px);
    }
    50% {
        transform: translateX(10px);
    }
}
</style>
