import App from './App.vue';
import Vue from 'vue';
import VueRouter from 'vue-router';
import TheModalWindow from '@/components/TheModalWindow.vue';
import store from './store';
import stripeService from '@/services/stripe/stripeService';

// Plugins
import DashboardPlugin from './material-dashboard';
import Toasted from 'vue-toasted';
import VueConfetti from 'vue-confetti';
import VueCarousel from 'vue-carousel';
import Chartist from 'chartist';
import Particles from '@tsparticles/vue2';
import { loadSlim } from '@tsparticles/slim';
import VuePrism from 'vue-prism';
import 'prismjs/themes/prism.css';
import 'ag-grid-community/styles/ag-grid.css'; // Mandatory CSS required by the Data Grid
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the Data Grid
import { AgGridVue } from 'ag-grid-vue'; // Vue Data Grid Component

// Router setup
import routes from './routes/routes';

// Stripe script
const stripeScript = document.createElement('script');
stripeScript.src = 'https://js.stripe.com/v3/pricing-table.js';
stripeScript.async = true;
document.head.appendChild(stripeScript);

// Tolt script
const toltScript = document.createElement('script');
toltScript.src = 'https://cdn.tolt.io/tolt.js';
toltScript.async = true;
toltScript.setAttribute('data-tolt', process.env.VUE_APP_TOLT_ID);
document.head.appendChild(toltScript);

// Use Vue plugins
Vue.use(VueRouter);
Vue.use(DashboardPlugin);
Vue.use(Toasted);
Vue.use(VueConfetti);
Vue.use(VueCarousel);
Vue.use(Particles, { init: async (engine) => await loadSlim(engine) });
Vue.use(VuePrism);

// Configure router
const router = new VueRouter({
    mode: 'history',
    routes, // short for routes: routes
    scrollBehavior: (to) => (to.hash ? { selector: to.hash } : { x: 0, y: 0 }),
    linkExactActiveClass: 'nav-item active',
});

// Helper functions
function redirectToDashboardIfNeeded(to, isAuthenticated) {
    const isPublicPath = ['/', '/register', '/invite', '/forgot-password'].includes(to.path);
    const shouldRedirect = isAuthenticated && isPublicPath && !to.query.logout;
    return shouldRedirect ? { name: 'Dashboard', query: to.query } : null;
}

function redirectToLoginIfNeeded(to, isAuthenticated) {
    if (to.matched.some((record) => record.meta.requiresAuth) && !isAuthenticated) {
        return { name: 'Login', query: to.query };
    }
    return null;
}

function checkRolePermission(to, isAuthenticated, userRole) {
    if (isAuthenticated && to.matched.some((record) => record.meta.requiresAuth)) {
        const requiredRoles = to.meta.permissions;
        const lacksRequiredRole = requiredRoles && !requiredRoles.includes(userRole);
        if (lacksRequiredRole) {
            return { name: 'Dashboard', query: to.query };
        }
    }
    return null;
}

async function syncMembership() {
    try {
        await store.dispatch('membership/getAccountMembership');
    } catch (error) {
        console.error('Error syncing membership: ', error);
    }
}

async function syncAccount() {
    try {
        await store.dispatch('user/actionGetAccount');
    } catch (error) {
        console.error('Error syncing account: ', error);
    }
}

function syncStripeCustomer() {
    if (!store.getters['user/isShopifyBillingClient']) {
        stripeService
            .fetchCustomer()
            .then(() => {})
            .catch((error) => {
                console.error('Error fetching Stripe customer:', error);
            });
    }
}

async function syncAgentSettings() {
    try {
        await store.dispatch('agentSettings/updateAgentSetting');
    } catch (error) {
        console.error('Error updating agent settings:', error);
    }
}

// Main Navigation Guard
router.beforeEach(async (to, from, next) => {
    const isAuthenticated = store.getters['user/ifAuthenticated'];
    const userRole = store.state.user.user.permission;

    // Redirect unauthenticated users to the login page for routes that require auth
    const loginRedirect = redirectToLoginIfNeeded(to, isAuthenticated);
    if (loginRedirect) return next(loginRedirect);

    // Redirect authenticated users from public paths to the dashboard
    const dashboardRedirect = redirectToDashboardIfNeeded(to, isAuthenticated);
    if (dashboardRedirect) {
        await syncAccount();
        await syncMembership();
        syncStripeCustomer();
        await syncAgentSettings();
        return next(dashboardRedirect);
    }

    // Check if the authenticated user has the required role for the route
    const rolePermissionRedirect = checkRolePermission(to, isAuthenticated, userRole);
    if (rolePermissionRedirect) {
        await syncAccount();
        await syncMembership();
        syncStripeCustomer();
        await syncAgentSettings();
        return next(rolePermissionRedirect);
    }

    // Proceed for all other cases
    const isPublicPath = ['/', '/register', '/invite', '/forgot-password'].includes(to.path);
    if (!isPublicPath) {
        await syncAccount();
        await syncMembership();
        syncStripeCustomer();
        await syncAgentSettings();
    }
    next();
});

// After each navigation, update document title
router.afterEach((to) => {
    Vue.nextTick(() => {
        document.title = to.meta.title ? `Lixsa | ${to.meta.title}` : 'Lixsa | Your Dream Team, Powered by AI';
    });
});

// Global library setup
Vue.prototype.$Chartist = Chartist;

// Register global components
Vue.component('TheModalWindow', TheModalWindow);
Vue.component('AgGridVue', AgGridVue);

// Initialize Vue instance
new Vue({
    el: '#app',
    render: (h) => h(App),
    store,
    router,
    created() {
        this.$store.dispatch('websocket/connectSocket').then(() => {
            if (this.$store.getters['user/ifAuthenticated']) {
                this.$store.dispatch('membership/subscribeToMembershipUpdates');
                this.$store.dispatch('agentSettings/subscribeToAgentSettingsUpdates');
            }
        });
    },
});
