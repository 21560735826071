import axios from 'axios';
import Firebase from '../../Firebase';

const getAuthHeader = (token, devMode) => {
    if (devMode) {
        return { 'X-API-KEY': token };
    } else {
        return { Authorization: `Bearer ${token}` };
    }
};

// initial state
const state = () => ({
    idToken: localStorage.getItem('token') || null,
    userId: localStorage.getItem('userId') || null,
    refreshToken: localStorage.getItem('refreshToken') || null,
    userProfilePicture: localStorage.getItem('userProfilePicture') || null,
    copilotEnabled: String(localStorage.getItem('copilotEnabled')).toLowerCase() === 'true' || false,
    devMode: String(localStorage.getItem('devMode')).toLowerCase() === 'true' || false,
    user: JSON.parse(localStorage.getItem('user')) || {
        uuid: null,
        external_id: null,
        email: null,
        username: null,
        account_id: null,
        status: null,
        permission: null,
        date_created: null,
        notifications_config: {
            pushNotificationIds: [],
            activeNotifications: {
                conversation: {
                    conversationSentimentChanged: false,
                    conversationWindowCreated: false,
                    conversationWindowUpdated: false,
                },
            },
        },
        invited: false,
    },
    account: JSON.parse(localStorage.getItem('account')) || {
        uuid: null,
        email: null,
        contact_name: null,
        status: null,
        current_plan: null,
        customer_support_email: null,
        api_url: null,
        onboarding_current_state: null,
        ecommerce_platform_type: null,
        shop_domain: null,
        shop_public_domain: null,
        shop_name: null,
        shop_phone: null,
        date_created: 0,
        next_step_instructions: null,
        business_type: null,
        source_platform: null,
        referred_by: null,
    },
});

// getters
const getters = {
    user(state) {
        return state.user;
    },

    idToken(state) {
        return state.idToken;
    },

    ifAuthenticated(state) {
        return state.idToken !== null;
    },

    isCopilotEnabled(state) {
        return state.copilotEnabled;
    },

    getShopName(state) {
        if (state.account.shop_domain) {
            let parts = state.account.shop_domain.split('.');
            return parts[0];
        }
        return state.account.contact_name;
    },

    isDevMode(state) {
        return state.devMode;
    },

    canAccess: (state) => (permissions) => {
        const userPermissions = state.user.permission;
        return permissions.includes(userPermissions);
    },

    isShopifyBillingClient: (state) => {
        return state.account.source_platform === 'SHOPIFY_BILLING';
    },

    toltReferral: (state) => {
        return state.account.referred_by || null;
    },
};

// actions
const actions = {
    async actionSignUpWithPopup({ commit }) {
        try {
            const res = await Firebase.signInWithGoogle();
            localStorage.setItem('token', res._tokenResponse.idToken);
            localStorage.setItem('userId', res._tokenResponse.localId);
            localStorage.setItem('refreshToken', res._tokenResponse.refreshToken);
            if (res._tokenResponse.photoUrl) {
                localStorage.setItem('userProfilePicture', res._tokenResponse.photoUrl);
                commit('SET_PROFILE_PICTURE', res._tokenResponse.photoUrl);
            }
            commit('authUser', {
                token: res._tokenResponse.idToken,
                userId: res._tokenResponse.localId,
                refreshToken: res._tokenResponse.refreshToken,
            });
            return Promise.resolve(res);
        } catch (error) {
            throw error;
        }
    },

    async actionSignUpWithFacebook({ commit }) {
        try {
            const res = await Firebase.signInWithFacebook();
            localStorage.setItem('token', res._tokenResponse.idToken);
            localStorage.setItem('userId', res._tokenResponse.localId);
            localStorage.setItem('refreshToken', res._tokenResponse.refreshToken);
            if (res._tokenResponse.photoUrl) {
                localStorage.setItem('userProfilePicture', res._tokenResponse.photoUrl);
                commit('SET_PROFILE_PICTURE', res._tokenResponse.photoUrl);
            }
            commit('authUser', {
                token: res._tokenResponse.idToken,
                userId: res._tokenResponse.localId,
                refreshToken: res._tokenResponse.refreshToken,
            });
            return Promise.resolve(res);
        } catch (error) {
            throw error;
        }
    },

    async actionSignup({ commit }, authData) {
        try {
            const res = await axios.post(
                `https://www.googleapis.com/identitytoolkit/v3/relyingparty/signupNewUser?key=${process.env.VUE_APP_FIREBASE}`,
                {
                    email: authData.email,
                    password: authData.password,
                    returnSecureToken: true,
                },
            );
            localStorage.setItem('token', res.data.idToken);
            localStorage.setItem('userId', res.data.localId);
            localStorage.setItem('refreshToken', res.data.refreshToken);
            commit('authUser', {
                token: res.data.idToken,
                userId: res.data.localId,
                refreshToken: res.data.refreshToken,
            });
            return Promise.resolve(res.data);
        } catch (err) {
            throw err;
        }
    },

    async actionLogin({ commit }, authData) {
        try {
            const res = await axios.post(
                `https://www.googleapis.com/identitytoolkit/v3/relyingparty/verifyPassword?key=${process.env.VUE_APP_FIREBASE}`,
                {
                    email: authData.email,
                    password: authData.password,
                    returnSecureToken: true,
                },
            );
            localStorage.setItem('token', res.data.idToken);
            localStorage.setItem('userId', res.data.localId);
            localStorage.setItem('refreshToken', res.data.refreshToken);
            if (res.data.profilePicture) {
                localStorage.setItem('userProfilePicture', res.data.profilePicture);
                commit('SET_PROFILE_PICTURE', res.data.profilePicture);
            }
            commit('authUser', {
                token: res.data.idToken,
                userId: res.data.localId,
                refreshToken: res.data.refreshToken,
            });
            return Promise.resolve(res.data);
        } catch (err) {
            throw err;
        }
    },

    async actionGetUser({ dispatch, commit, state }, customUserId = null) {
        const newUserId = customUserId ? customUserId : state.userId;
        try {
            const responseStatus = await dispatch('apiCall', async (token) => {
                const res = await axios.get(`${process.env.VUE_APP_API_URL}/users/${newUserId}`, {
                    headers: getAuthHeader(token, state.devMode),
                });
                return res;
            });
            localStorage.setItem('user', JSON.stringify(responseStatus.data));
            commit('SET_USER', responseStatus.data);
            return responseStatus;
        } catch (err) {
            console.error(err);
            throw err;
        }
    },

    logout({ dispatch, commit }) {
        Firebase.SignOut()
            .then(() => {
                // Limpiar el estado de Vuex
                commit('clearAuth');
                // Eliminar datos del localStorage
                localStorage.removeItem('token');
                localStorage.removeItem('userId');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('user');
                localStorage.removeItem('account');
                localStorage.removeItem('userProfilePicture');
                localStorage.removeItem('copilotEnabled');
                localStorage.removeItem('devMode');

                dispatch('agentSettings/clearAgentSettingsState', null, { root: true });

                // Clear membership module state.
                dispatch('membership/clearMembershipState', null, { root: true });
                dispatch('membership/clearStripeCustomerState', null, { root: true });

                // Unsubscribe from all websocket topics
                dispatch('websocket/unsubscribeFromAll', null, { root: true });
            })
            .catch((error) => {
                // Manejar errores
                console.error('Error cerrando sesión en Firebase:', error);
            });
    },

    async refreshToken({ commit, state }) {
        try {
            const headers = { 'Content-Type': 'application/x-www-form-urlencoded' };
            const data = new URLSearchParams({
                grant_type: 'refresh_token',
                refresh_token: state.refreshToken,
            });
            const response = await axios.post(
                `https://securetoken.googleapis.com/v1/token?key=${process.env.VUE_APP_FIREBASE}`,
                data,
                { headers },
            );
            localStorage.setItem('token', response.data.idToken);
            commit('SET_TOKEN', response.data.id_token);
            return Promise.resolve(response.data.id_token);
        } catch (error) {
            console.error('Error refreshing token:', error.message);
            throw error;
        }
    },

    async apiCall({ dispatch, state }, apiFunction, cancelToken = null) {
        const options = cancelToken ? { cancelToken: cancelToken.token } : {};
        if (state.devMode) return await apiFunction(state.idToken, options);

        try {
            // Pasar el cancelToken como parte de las opciones si está disponible
            return await apiFunction(state.idToken, options);
        } catch (error) {
            console.log(error);
            if (axios.isCancel(error)) {
                // Manejar específicamente la cancelación de la petición
                console.log('Request canceled', error.message);
                throw error;
            } else if (error.response && (error.response.status === 401 || error.response.status === 0)) {
                // El token ha expirado o no es válido, intenta refrescarlo
                const newToken = await dispatch('refreshToken');
                // Pasar el cancelToken de nuevo si la primera petición fue cancelada
                return await apiFunction(newToken, options);
            } else {
                throw error;
            }
        }
    },

    // eslint-disable-next-line no-unused-vars
    async actionCreateAccount({ state }, formData) {
        const { uuid, email, contact_name, user_id, source_platform, referredBy } = formData;
        const data = { uuid, email, user_id, contact_name, source_platform, referred_by: referredBy };
        try {
            await axios.post(`${process.env.VUE_APP_API_URL}/accounts`, data);
            return formData;
        } catch (err) {
            console.error('Error creating account:', err.message);
            throw err;
        }
    },

    async actionUpdateEcommerceSettings({ dispatch, state }, formData) {
        try {
            const { shop_domain, shop_access_token } = formData;
            const data = { shop_domain, shop_access_token };
            const responseStatus = await dispatch('apiCall', async (token) => {
                const response = await axios.post(
                    `${process.env.VUE_APP_API_URL}/accounts/${state.user.account_id}/update-ecommerce-settings`,
                    data,
                    {
                        headers: getAuthHeader(token, state.devMode),
                    },
                );
                return response.status;
            });
            return responseStatus;
        } catch (err) {
            throw err;
        }
    },

    async actionGetAccount({ dispatch, commit, state }) {
        try {
            return await dispatch('apiCall', async (token) => {
                const response = await axios.get(`${process.env.VUE_APP_API_URL}/accounts/${state.user.account_id}`, {
                    headers: getAuthHeader(token, state.devMode),
                });

                localStorage.setItem('account', JSON.stringify(response.data));
                commit('SET_ACCOUNT', response.data);
                return response;
            });
        } catch (err) {
            console.log('Error in getting account: ', err);
            throw err;
        }
    },

    async actionUpdateUser({ dispatch, state }, formData) {
        const { user_id, username } = formData;
        const data = {
            user_id,
            username,
            account_id: state.user.account,
            permission: state.user.permission,
        };
        try {
            return await dispatch('apiCall', async (token) => {
                const response = await axios.post(
                    `${process.env.VUE_APP_API_URL}/accounts/${state.user.account_id}/users/${state.user.uuid}`,
                    data,
                    {
                        headers: getAuthHeader(token, state.devMode),
                    },
                );
                return response;
            });
        } catch (err) {
            throw err;
        }
    },

    async actionUpdateAccount({ dispatch, state }, formData) {
        // Initialize data with account_id which is always required
        const data = {
            account_id: state.user.account_id,
        };

        // Add only the fields that are provided in formData
        const fields = [
            'email',
            'contact_name',
            'customer_support_email',
            'shop_name',
            'shop_phone',
            'shop_domain',
            'shop_public_domain',
            'shop_currency',
            'short_business_description',
            'business_type',
            'source_platform',
        ];

        fields.forEach((field) => {
            if (formData[field] !== undefined && formData[field] !== null) {
                data[field] = formData[field];
            }
        });

        try {
            return await dispatch('apiCall', async (token) => {
                const response = await axios.post(
                    `${process.env.VUE_APP_API_URL}/accounts/${state.user.account_id}`,
                    data,
                    {
                        headers: getAuthHeader(token, state.devMode),
                    },
                );
                return response;
            });
        } catch (err) {
            throw err;
        }
    },

    enableCopilot({ commit }) {
        localStorage.setItem('copilotEnabled', true);
        commit('ENABLE_COPILOT');
    },

    disableCopilot({ commit }) {
        localStorage.setItem('copilotEnabled', false);
        commit('DISABLE_COPILOT');
    },

    setIdToken({ commit }, token) {
        localStorage.setItem('token', token); // Save the new token to localStorage
        commit('SET_TOKEN', token); // Commit the mutation to update the state
    },

    async toggleDevMode({ commit, dispatch, state }) {
        commit('SET_DEV_MODE', !state.devMode); // Toggle the current state
        if (!state.devMode) {
            await dispatch('refreshToken');
        }
    },

    async setDevMode({ commit, dispatch, state }, value) {
        commit('SET_DEV_MODE', value); // Set a specific value
        if (!state.devMode) {
            await dispatch('refreshToken');
        }
    },

    async updateUserAccountId({ commit, state }, newAccountId) {
        commit('SET_USER_ACCOUNT_ID', newAccountId);

        // Update the user object in the Vuex store
        const updatedUser = { ...state.user, account_id: newAccountId };

        // Serialize the updated user object and save it back to localStorage
        localStorage.setItem('user', JSON.stringify(updatedUser));
    },

    updateNotificationsConfig({ commit }, { pushNotificationIds, activeNotifications }) {
        commit('UPDATE_NOTIFICATIONS_CONFIG', { pushNotificationIds, activeNotifications });
    },
};

// mutations
const mutations = {
    authUser(state, userData) {
        state.idToken = userData.token;
        state.userId = userData.userId;
        state.refreshToken = userData.refreshToken;
    },

    SET_TOKEN(state, token) {
        state.idToken = token;
    },

    SET_USER(state, user) {
        state.user = { ...user };
    },

    SET_ACCOUNT(state, account) {
        state.account = { ...account };
    },

    SET_PROFILE_PICTURE(state, userProfilePicture) {
        state.userProfilePicture = userProfilePicture;
    },

    clearAuth(state) {
        state.idToken = null;
        state.userId = null;
        state.refreshToken = null;
        state.userProfilePicture = null;
        state.copilotEnabled = false;
        state.devMode = false;
        state.user = {
            uuid: null,
            account_id: null,
            username: null,
            permission: null,
            email: null,
        };
        state.account = {
            uuid: null,
            email: null,
            contact_name: null,
            status: null,
            current_plan: null,
            customer_support_email: null,
            api_url: null,
            onboarding_current_state: null,
            ecommerce_platform_type: null,
            shop_domain: null,
            shop_public_domain: null,
            shop_name: null,
            shop_phone: null,
            date_created: 0,
            next_step_instructions: null,
        };
    },

    ENABLE_COPILOT(state) {
        state.copilotEnabled = true;
    },

    DISABLE_COPILOT(state) {
        state.copilotEnabled = false;
    },

    SET_DEV_MODE(state, value) {
        state.devMode = value;
        localStorage.setItem('devMode', value); // Persist to localStorage
    },

    SET_USER_ACCOUNT_ID(state, accountId) {
        state.user.account_id = accountId;
    },

    UPDATE_NOTIFICATIONS_CONFIG(state, { pushNotificationIds, activeNotifications }) {
        state.user.notifications_config = {
            ...state.user.notifications_config,
            pushNotificationIds: pushNotificationIds || state.user.notifications_config.pushNotificationIds,
            activeNotifications: {
                ...state.user.notifications_config.activeNotifications,
                ...activeNotifications,
            },
        };

        // Update localStorage
        const updatedUser = { ...state.user };
        localStorage.setItem('user', JSON.stringify(updatedUser));
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
