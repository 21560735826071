import membershipDto from '@/store/dto/membership/membershipDto';
import stripeCustomerDto from '@/store/dto/membership/stripeCustomerDto';
import accountService from '@/services/account/accountService';
import shopifyBillingService from '@/services/shopify/shopifyBillingService';
const state = () => ({
    membershipState: JSON.parse(localStorage.getItem('accountMembership')) || membershipDto,
    stripeCustomerState: JSON.parse(localStorage.getItem('stripeCustomer')) || stripeCustomerDto,
});

const getters = {
    membership(state) {
        return state.membershipState;
    },

    stripeCustomer(state) {
        return state.stripeCustomerState;
    },

    ifSubscribed(state) {
        // return state.stripeCustomerState.subscriptions.data.length > 0;
        return state.membershipState.membership_plan !== 'FREE';
    },

    findProductId(state, getters) {
        if (!getters.ifSubscribed) return null;

        const subscriptionItems = state.stripeCustomerState.subscriptions.data[0].items.data;
        if (subscriptionItems && subscriptionItems.length > 0) {
            return subscriptionItems[0].plan.product;
        }
    },

    membershipPlanName: (state) => {
        return state.membershipState.membership_plan;
    },

    subscriptionEndDate: (state) => {
        return state.membershipState.subscription_end_date;
    },

    subscriptionTrialEndDate: (state) => {
        return state.stripeCustomerState.subscriptions.data[0].trial_end;
    },

    shopifySubscriptionTrialEndDate: (state) => {
        return state.membershipState.trial_end_date;
    },

    consumedConversations: (state, getters) => {
        return getters.totalConversations - state.membershipState.total_conversations || 0;
    },

    totalConversations: (state) => {
        return (
            state.membershipState.membership_conversations +
                state.membershipState.pack_conversations +
                state.membershipState.prorated_conversations || 0
        );
    },

    AreConversationsConsumed: (state, getters) => {
        return getters.consumedConversations === getters.totalConversations;
    },

    isInTrialPeriod: (state, getters) => {
        if (!getters.ifSubscribed) return null;

        return state.stripeCustomerState.subscriptions.data[0].status === 'trialing';
    },

    shopifyIsInTrialPeriod: (state) => {
        const currentTimestamp = Math.floor(Date.now() / 1000);
        const trialEndTimestamp = state.membershipState.trial_end_date;
        return currentTimestamp < trialEndTimestamp;
    },

    cancelAtPeriodEnd: (state) => {
        return state.stripeCustomerState.subscriptions.data[0].cancel_at_period_end;
    },

    shopifyCancelAtPeriodEnd: (state) => {
        return state.membershipState.cancel_at_period_end;
    },

    cancelAt: (state) => {
        return state.stripeCustomerState.subscriptions.data[0].cancel_at;
    },

    shopifyCancelAt: (state) => {
        return state.membershipState.subscription_cancel_at;
    },

    membershipPlan: (state) => {
        return state.membershipState.membership_plan;
    },
};

const mutations = {
    SET_MEMBERSHIP_STATE(state, newMembershipState) {
        state.membershipState = JSON.parse(JSON.stringify(newMembershipState));
        localStorage.setItem('accountMembership', JSON.stringify(newMembershipState));
    },

    CLEAR_MEMBERSHIP_STATE(state) {
        state.membershipState = membershipDto;
    },

    SET_STRIPE_CUSTOMER_STATE(state, newStripeCustomerState) {
        state.stripeCustomerState = JSON.parse(JSON.stringify(newStripeCustomerState));
        localStorage.setItem('stripeCustomer', JSON.stringify(newStripeCustomerState));
    },

    CLEAR_STRIPE_CUSTOMER_STATE(state) {
        state.stripeCustomerState = stripeCustomerDto;
    },
};

const actions = {
    clearMembershipState({ commit }) {
        localStorage.removeItem('accountMembership');
        commit('CLEAR_MEMBERSHIP_STATE');
    },

    clearStripeCustomerState({ commit }) {
        localStorage.removeItem('stripeCustomer');
        commit('CLEAR_STRIPE_CUSTOMER_STATE');
    },

    async getAccountMembership({ commit }) {
        try {
            const getAccountMembershipResponse = await accountService.fetchAccountMembership();

            commit('SET_MEMBERSHIP_STATE', getAccountMembershipResponse.data);

            return getAccountMembershipResponse;
        } catch (e) {
            console.error('Error in getting account membership: ', e);
        }
    },

    async cancelShopifySubscription(_, membershipId) {
        try {
            const response = await shopifyBillingService.cancelShopifySubscription(membershipId);
            return response.data;
        } catch (error) {
            throw error;
        }
    },

    async getAccountMembershipByExternalBillingId({ commit }, externalBillingId) {
        try {
            const response = await accountService.getAccountMembershipByExternalBillingId(externalBillingId);

            commit('SET_MEMBERSHIP_STATE', response.data);

            return response.data;
        } catch (error) {
            throw error;
        }
    },

    async subscribeToMembershipUpdates({ state, dispatch }) {
        const topic = `/account/${state.membershipState.account_id}/membership`;
        try {
            await dispatch(
                'websocket/subscribe',
                {
                    topic,
                    callback: (data) => {
                        dispatch('handleMembershipUpdate', data);
                    },
                },
                { root: true },
            );
        } catch (error) {
            console.error(`Failed to subscribe to ${topic}:`, error);
        }
    },

    unsubscribeFromMembershipUpdates({ state, dispatch }) {
        const topic = `/account/${state.membershipState.account_id}/membership`;
        dispatch('websocket/unsubscribe', { topic }, { root: true });
    },

    handleMembershipUpdate({ commit }, event) {
        if (!event || !event.data) return;

        try {
            const parsedData = JSON.parse(event.data);
            switch (event.name) {
                case 'MembershipUpdated':
                case 'MembershipDowngraded':
                    commit('SET_MEMBERSHIP_STATE', parsedData);
                    break;
                // Add more cases if needed
                default:
                    console.warn(`Unhandled membership event: ${event.name}`);
            }
        } catch (error) {
            console.error('Error parsing membership update data:', error);
        }
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions,
};
