import conversationsService from '../../../../services/conversations/conversationsService.js';
import { transformDate, formatDateTime, transformDateLong } from '@/utils/common';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

export default {
    data() {
        return {
            isOnSearch: false,
            lastSearchTerm: '',
        };
    },
    methods: {
        async fetchSearchRooms(info) {
            await new Promise((resolve) => setTimeout(resolve, 500)); // Add 500ms delay

            const searchTerm = info.value.toString().trim();
            this.lastSearchTerm = searchTerm;

            if (searchTerm.length === 0) {
                return this.resetSearch();
            }

            this.prepareForSearch();

            try {
                await this.performSearch(searchTerm);
            } catch (error) {
                this.handleSearchError(error);
            }
        },

        async resetSearch() {
            this.currentRoomPage = 0;
            this.isOnSearch = false;
            this.lastDateRooms = '';
            this.lastSearchTerm = '';
            await this.subscribeToRoomsList();
            this.fetchRooms();
            this.resetUrl();
        },

        prepareForSearch() {
            // this.rooms = [];
            // this.customRooms = [];
            this.currentRoomPage = 0;
            this.isOnSearch = true;
            this.unsubscribeFromCurrentRoom();
        },

        async unsubscribeFromCurrentRoom() {
            await this.unsubscribeToRoomsList();
            await this.unsubscribeToRoomMessages(this.currentRoomId);
        },

        async performSearch(searchTerm) {
            try {
                const response = await conversationsService.searchRooms(
                    searchTerm,
                    this.currentRoomPage,
                    this.roomsPerPage,
                );
                this.totalRooms = response.data.totalElements;
                this.currentRoomPage++;
                this.handleSearchResponse(response);
                if (this.rooms.length === this.totalRooms) {
                    this.roomsLoaded = true;
                }
            } catch (error) {
                this.handleSearchError(error);
            }
        },

        handleSearchResponse(response) {
            // this.rooms = [];
            // this.customRooms = [];
            this.processDataSearch(response.data);
        },

        handleSearchError(error) {
            if (!axios.isCancel(error)) {
                console.error('Error during search: ', error);
            }
        },

        resetUrl() {
            const currentUrl = window.location.href;
            window.history.pushState({}, '', currentUrl.split('?')[0]);
        },

        processDataSearch(data) {
            if (this.currentRoomPage === 1) {
                this.resetRooms();
            }

            if (data.content.length === 0) {
                this.loadingRooms = false;
                return;
            }

            this.loadingRooms = true;

            if (this.rooms.length === 0 && data.content.length > 0) {
                this.updateGlobalInfo(data.content[0]);
                this.fetchMessages();
            }

            const processedData = data.content.map(this.mapItemToRoomInfo);
            this.updateRoomData(processedData);
            this.loadingRooms = false;
        },

        clearRooms() {
            this.rooms = [];
            this.customRooms = [];
        },

        mapItemToRoomInfo(item) {
            return {
                ...this.getBasicRoomInfo(item),
                ...this.getUserInfo(item),
                ...this.getChannelInfo(item),
                lastMessage: this.getLastMessageInfo(item),
                users: this.getUsersInfo(item),
                ...this.getAdditionalInfo(item),
            };
        },

        updateGlobalInfo(item) {
            this.lastDateRooms = item.date_updated;
            this.currentUserId = item.account;
            this.roomId = item.uuid;
            // this.updateRoomId(item.uuid);
            this.currentIdCustomer = item.customer_id;
            this.updateCurrentUserInfo(item);
        },

        updateCurrentUserInfo(item) {
            this.currentUserName = item.customer_lastname
                ? `${item.customer_lastname} ${item.customer_first_name}`
                : item.customer_first_name;

            this.currentUserEmail = item.customer_email || null;
            this.currentUserPhone = item.customer_phone || item.channel_id;
            this.currentUserSatisfaction = item.satisfaction !== 'UNDEFINED' ? item.satisfaction : 'NOT ANALYZED';
        },

        getBasicRoomInfo(item) {
            return {
                roomId: item.uuid,
                currentUserId: item.account,
                roomName: item.customer_lastname
                    ? `${item.customer_lastname} ${item.customer_first_name}`
                    : item.customer_first_name,
            };
        },

        getUserInfo(item) {
            return {
                userEmail: item.customer_email || null,
                userPhone: item.customer_phone || item.channel_id,
                userSatisfaction: item.satisfaction !== 'UNDEFINED' ? item.satisfaction : 'NOT ANALYZED',
            };
        },

        getChannelInfo(item) {
            return {
                avatar: this.getAvatarUrl(),
                channelId: item.channel_id,
                channel_type: item.channel_type,
                communication_channel: item.channel_type,
            };
        },

        getAvatarUrl() {
            return 'https://firebasestorage.googleapis.com/v0/b/lixsa-prod.appspot.com/o/public_images%2Ffoto%20perfil%20usuario.png?alt=media&token=5470816a-d159-4958-aae2-5837c747339c';
        },

        getLastMessageInfo(item) {
            let lastMessageUuid = '';
            let lastMessageContent = '';

            if (item.last_message.uuid === null) {
                const myUuid = uuidv4();
                lastMessageUuid = myUuid;
                lastMessageContent = '.';
            }

            lastMessageContent = this.getLastMessageContentByType(item);
            return {
                _id: item.last_message.uuid !== null ? item.last_message.uuid : lastMessageUuid,
                content: lastMessageContent,
                senderId: item.customer_id,
                username:
                    item.role_type === 'HUMAN' ? `${item.customer_lastname} ${item.customer_first_name}` : 'Lixsa',
                timestamp: transformDate(item.date_updated),
                date: transformDateLong(item.date_updated),
                saved: true,
                distributed: false,
                seen: true,
                new: true,
            };
        },

        getUsersInfo(item) {
            return [this.getLixsaUserInfo(), this.getCustomerUserInfo(item)];
        },

        getLixsaUserInfo() {
            return {
                _id: '4321',
                username: 'Lixsa',
                avatar: 'https://firebasestorage.googleapis.com/v0/b/lixsa-prod.appspot.com/o/public_images%2Ffoto%20perfil%20Lixsa.png?alt=media&token=7430b983-430a-484c-9acb-6caa792ccf2c',
                status: { state: 'offline', lastChanged: '14 July, 20:00' },
            };
        },

        getCustomerUserInfo(item) {
            return {
                _id: item.customer_id, //"4321",
                username: item.customer_lastname + ' ' + item.customer_first_name,
                avatar: this.getAvatarUrl(),
                status: {
                    state: 'offline',
                    lastChanged: item.date_updated ? formatDateTime(item.date_updated) : 'today, 14:30',
                },
            };
        },

        getAdditionalInfo(item) {
            return {
                unreadCount: 0,
                index: 1,
                typingUsers: [4321],
                is_aion: item.is_aion,
                room_ai_type: item.ai_type,
                note: item.note,
            };
        },

        updateRoomData(processedData) {
            this.reorderRooms(processedData);
            this.rooms = this.customRooms;
        },
    },
};
